<template>
  <div class="ask-teva">
    <div
      class="game-wrapper"
      :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
        { stage7: stage === 7 },
        { stage8: stage === 8 },
        { stage9: stage === 9 },
      ]"
    >
      <div class="game-wrapper__stage" v-show="stage === 1">
        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text green">
            Знаю, у нас мало часу, але потрібно забігти в аптеку…<br />Дуже
            швидко
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-1-circle.png" alt="" />
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(2)"
        >
          <p>
            До аптеки
          </p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 2">
        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text grey">
            Окей, ще запитання. А що буде, якщо я змішаю корінь лопуха з
            настоянкою полину?
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-2-circle.png" alt="" />
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(3)"
        >
          <p>
            Відповісти
          </p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 3">
        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-3-circle.png" alt="" />

          <div class="game-wrapper__stage--white-block bottom stage3">
            <p class="game-wrapper__stage--text blue">
              У нас немає лопуха та полину, але є багато іншого у відділі з
              травами. Пройдіть та подивіться
            </p>

            <div class="game-wrapper__stage--arrow-up"></div>
          </div>
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(4)"
        >
          <p>Наступний клієнт</p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 4">
        <div class="game-wrapper__stage--white-block stage4">
          <p class="game-wrapper__stage--text green">
            Нарешті наша черга!<br />
            Дайте, будь ласка, <span>асаль-ситин-цилову кислоту…</span>
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-4-circle.png" alt="" />
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(5)"
        >
          <p>
            Уточнити
          </p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 5">
        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-5-circle.png" alt="" />

          <div class="game-wrapper__stage--white-block bottom stage5">
            <p class="game-wrapper__stage--text blue">
              Ви маєте на увазі <span>ацетилсаліцилову?</span>
            </p>
            <div class="game-wrapper__stage--arrow-up"></div>
          </div>
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(6)"
        >
          <p>Далі</p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 6">
        <div class="game-wrapper__stage--white-block stage6">
          <p class="game-wrapper__stage--text green">
            Оооо, точно! Ми пів дня намагались згадати цю назву
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-4-circle.png" alt="" />
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(7)"
        >
          <p>
            Поділитись лайфхаком
          </p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 7">
        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-asa/stage-5-circle.png" alt="" />

          <div class="game-wrapper__stage--white-block bottom stage7">
            <p class="game-wrapper__stage--text blue">
              У нас є <span>АСК-Тева</span> – АцетилСаліцилова Кислота, яка Вам потрібна!
            </p>
            <div class="game-wrapper__stage--arrow-up"></div>
          </div>
        </div>

        <div
          class="game-wrapper__stage--btn-next-stage hover_type6"
          @click="nextStage(8)"
        >
          <p>До матеріальної кімнати</p>
        </div>
      </div>

      <div class="game-wrapper__stage" v-show="stage === 8">
        <div class="game-wrapper__stage--white-block popup stage8">
          <div class="game-wrapper__stage--top-text">
            <svg
              viewBox="0 0 254 254"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="115.745"
                cy="115.745"
                r="81.8442"
                transform="rotate(-45 115.745 115.745)"
                stroke="white"
                stroke-width="8.92846"
              />
              <path
                d="M74.7078 156.782C52.0438 134.118 52.0438 97.3724 74.7078 74.7083"
                stroke="white"
                stroke-width="8.92846"
              />
              <path
                d="M158.887 182.036L182.036 158.887L243.065 219.916C248.876 225.727 248.876 235.149 243.065 240.96L240.961 243.065C235.149 248.876 225.727 248.876 219.916 243.065L158.887 182.036Z"
                fill="white"
              />
            </svg>
          </div>

          <div class="game-wrapper__stage--bottom-text">
            Лікарський засіб якраз є в наявності, проте ми з Вами знаємо, що
            іноді знайти його не так легко. Тому уявіть себе героєм
            пригодницького фільму та вперед до пошуку!
          </div>

          <div
            class="game-wrapper__stage--btn-next-stage small-m hover_type6"
            @click="nextStage(9)"
          >
            <p>Вперед!</p>
          </div>
        </div>
      </div>

      <asaGame v-if="stage === 9"></asaGame>
    </div>

    <div
      v-show="stage !== 9"
      class="notes-wrapper"
      :class="{ active: stage === 8 }"
    >
      <span>
        Інформація про лікарський засіб. РП №UA/14167/01/02, №UA/14167/01/01.
        Для професійної діяльності медичних та фармацевтичних працівників.
      </span>

      <span>
        АСК-Тева (1 таблетка містить ацетилсаліцилової кислоти 75 мг або 100
        мг). Характеристики: таблетки по 75 мг: білі, овальні, двоопуклі,
        кишковорозчинні таблетки; таблетки по 100 мг: білі, круглі, двоопуклі,
        кишковорозчинні таблетки. Лікувальні властивості: антитромботичні
        засоби. Найбільш частими побічними реакціями є: мікрокровотечі (70%),
        шлункові симптоми, біль у животі, печія.
      </span>

      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284.
      </span>
      <span>CBG-UA-00894. Термін дії матеріалу – 10.2024.</span>
    </div>

    <img
      v-if="
        stage === 1 ||
        stage === 2 ||
        stage === 3 ||
        stage === 4 ||
        stage === 5 ||
        stage === 6 ||
        stage === 7 ||
        stage === 9
      "
      class="bg"
      src="../../assets/img/game-asa/bg.png"
      alt=""
    />

    <img
      v-else
      class="bg"
      src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
      alt=""
    />
  </div>
</template>

<script>
import asaGame from "../../components/Asa-game.vue";

export default {
  name: "ask-teva",
  data: () => ({
    stage: 1,
  }),
  components: {
    asaGame,
  },
  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.ask-teva {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage9 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;
        height: 110px;

        background: #ffffff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: -20px;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          padding: 0;
          padding-bottom: 30px;
          height: auto;

          img {
            max-width: 394px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.grey {
          color: $grey;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;
        margin-top: 40px;

        @media screen and (max-width: 425px) {
          max-width: 100%;
        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 40px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;
        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        padding: 0 20px;

        span {
          font-family: $EB;
          display: block;
          margin-top: 12px;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--arrow-up {
        position: absolute;
        top: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-bottom: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 21px 30px 21px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;
        width: 100%;

        svg {
          max-width: 253px;
          width: 100%;
          height: auto;

          display: block;
          margin: 0 auto;
        }
      }

      &--bottom-text {
        color: #424242;
        font-size: 24px;
        line-height: 120%;

        font-family: $M;

        padding: 43px 30px 35px 30px;
        text-align: center;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .ask-teva {
    padding-top: 0;

    .bg {
    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &.stage3 {
      }

      &__stage {
        &--white-block {
          height: 100px;
          padding: 10px;

          &.bottom {
            bottom: -15px;
          }

          &.popup {
            padding: 0;
            padding-bottom: 12px;
            height: auto;

            img {
              max-width: 236px;
            }
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {
          margin-top: 55px;
          padding-left: 10px;
          padding-right: 10px;
        }

        &--btn-next-stage {
          margin-top: 25px;
          height: 50px;

          &.small-p {
          }

          &.small-m {
            margin-top: 0 !important;
          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }

        &--popup-text {
          span {
            margin-top: 6px;
          }
        }

        &--arrow-down {
        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 7px 20px 12px 20px;

          svg {
            max-width: 170px;
          }
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 46px 20px 16px 20px;
        }
      }
    }

    .notes-wrapper {
      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
